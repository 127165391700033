
import {computed, defineComponent} from 'vue'
import {LoadEntity, LoadPanel, LoadTagModal} from "@/core/composite/composite";
import {Actions, Mutations} from "@/store/enums/StoreEnums";
import store from "@/store";
import EntityView from "@/components/base/common/EntityView.vue";
import {onUpdated} from "@vue/runtime-core";
import {setCurrentPageBreadcrumbsWithParams} from "@/core/helpers/breadcrumb";
import ProcessType from "@/views/process/ProcessType.vue";
import ProcessStatus from "@/views/process/ProcessStatus.vue";
import DateTimeFormat from "@/components/base/datetime/DateTimeFormat.vue";
import Avatar from "@/components/base/common/Avatar.vue";
import CaseLink from "@/views/case/CaseLink.vue";
import ClaimLink from "@/views/claim/ClaimLink.vue";
import InvoiceLink from "@/views/invoice/InvoiceLink.vue";
import TabNavigate from "@/components/base/common/TabNavigate.vue";
import QuickAction from "@/components/base/action/QuickAction.vue";
import BaseModal from "@/components/base/modal/BaseModal.vue";
import ProcessUpdate from "@/views/process/ProcessUpdate.vue";
import Swal from "sweetalert2";
import ProcessService from "@/core/services/ProcessService";
import {PROCESS_TYPE} from "@/core/config/Constant";
import Duration from "@/views/activity/Duration.vue";
import TagEntities from "@/views/tag/TagEntities.vue";

export default defineComponent({
  name: "Process",
  components: {
    TagEntities,
    Duration,
    ProcessUpdate,
    BaseModal,
    QuickAction,
    TabNavigate,
    InvoiceLink, ClaimLink, CaseLink, Avatar, DateTimeFormat, ProcessStatus, ProcessType, EntityView
  },
  setup() {
    const entity = computed(() => store.state.ProcessModule.entity);
    onUpdated(() => {
      setCurrentPageBreadcrumbsWithParams('Process Details', [
        {link: true, router: '/list-process/' + entity.value.type, text: PROCESS_TYPE[entity.value.type].names},
        {link: false, router: '', text: 'View Process'}
      ])
    })

    return {
      ...LoadEntity(Actions.LOAD_PROCESS),
      entity,
      ...LoadPanel(),
      ...LoadTagModal(),
    }
  },
  methods: {
    onUpdate() {
      const modal = this.$refs.updateProcessStatusRef as typeof BaseModal
      modal.showBaseModal();
    },
    onSaved() {
      const modal = this.$refs.updateProcessStatusRef as typeof BaseModal
      modal.hideBaseModal();
      this.reloadData()
      store.commit(Mutations.ADDED_TAGS)
    },
    onDelete(process) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
        showLoaderOnConfirm: true,
        heightAuto: false,
        preConfirm: async () => {
          await ProcessService.delete(process.id).then(() => {
            if (process.caze?.id) {
              this.$router.push({
                name: 'caseOverview',
                params: {id: process.caze.id}
              })
            }
            if (process.claim?.id) {
              this.$router.push({
                name: 'claimOverview',
                params: {id: process.claim.id}
              })
            }
            if (process.invoice?.id) {
              this.$router.push({
                name: 'invoiceOverview',
                params: {id: process.invoice.id}
              })
            }
          })
        }
      })
    }
  }
})
