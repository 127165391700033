
import {defineComponent, ref, watch} from 'vue'
import BaseForm from "@/components/base/form/BaseForm.vue";
import Froala from "@/components/base/editor/Froala.vue";
import FormErrorMessage from "@/components/base/form/FormErrorMessage.vue";
import UserListSelect from "@/components/common/UserListSelect.vue";
import BaseRadio from "@/components/base/form/BaseRadio.vue";
import {Field} from "vee-validate";
import ProcessService from "@/core/services/ProcessService";

export default defineComponent({
  name: "ProcessUpdate",
  components: {BaseRadio, UserListSelect, FormErrorMessage, Froala, BaseForm, Field},
  props: {
    id: {type: String, required: true},
    process: {type: Object, required: true},
    types: {}
  },
  emits: ['saved'],
  setup(props) {
    const model = ref(props.process);
    watch(() => props.process, cb => {
      model.value = cb;
    })
    const submitting = ref(false);
    return {
      submitting,
      model,
    }
  },
  methods: {
    onSubmit() {
      this.submitting = true;
      ProcessService.update(this.id, this.model).then(res => {
        this.$emit('saved', res);
      }).finally(() => {
        this.submitting = false;
      })
    }
  }
})
