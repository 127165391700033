
import {defineComponent, ref, watch} from "vue";
import ActivityService from "@/core/services/ActivityService";

export default defineComponent({
  name: "Duration",
  props: {
    objectType: {required: false, default: 'CASE'},
    objectId: {type: String,}
  },
  setup(props) {
    const format = ref('00:00');
    const load = (type, id) => {
      ActivityService.getTimeSpent(type, id).then(res => {
        const hours = Math.floor(res.total / 60);
        const minutes = res.total % 60;
        format.value = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
      })
    }
    load(props.objectType, props.objectId)
    watch(() => props, () => {
      load(props.objectType, props.objectId)
    })
    return {
      format
    }
  },
})
